import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from "gatsby-plugin-intl";
import Img from "gatsby-image";
import ReactMarkdown from 'react-markdown';

import './indexHero.scss';

const Block = ({ content, image }) => {
  return (
    <div className="content">
      <div className="page-index__hero">
        <div className="page-index__hero__image wow slideInUp">
          <Img fluid={image.childImageSharp.fluid} alt="" />
        </div>
        <div className="page-index__hero__text wow slideInRight">
          <ReactMarkdown source={content} />
        </div>
      </div>
    </div>
  );
};

Block.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locale: PropTypes.string,
  }),
  content: PropTypes.string,
  image: PropTypes.object,
}

export default injectIntl(Block)
