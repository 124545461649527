import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from "gatsby-plugin-intl";

import './indexJoins.scss';

const TIMEOUT = 4000;

const Block = ({ itemsStr, header }) => {
  const items = itemsStr.split('\n').map(s => s.split(','));
  const [lineNum, setLineNum] = React.useState(0);

  const showLine = (num) => {
    if (!document) return;
    document.querySelectorAll('.page-index__joins__show .joins-line').forEach(el => el.classList.remove('joins-line--active'));
    document.querySelectorAll(`.page-index__joins__show .joins-line--${num}`).forEach(el => el.classList.add('joins-line--active'));
    const line1Width = document.querySelector(`.page-index__joins__show__line1 .joins-line--${num}`).offsetWidth;
    document.querySelector(`.page-index__joins__show__line1 .point-line`).style.marginLeft = `${line1Width}px`;
    const line2Width = document.querySelector(`.page-index__joins__show__line2 .joins-line--${num}`).offsetWidth;
    document.querySelector(`.page-index__joins__show__line2 .point-line`).style.marginLeft = `${line2Width}px`;
  };

  React.useEffect(() => {
    if (setTimeout) {
      let timer = setTimeout(() => {
        const line = lineNum + 1 < items.length ? lineNum + 1 : 0;
        setLineNum(line);
        showLine(line);
      }, TIMEOUT);
        showLine(lineNum);
      return () => {
        clearTimeout(timer);
      }
    }
  });

  return (
    <div className="content">
      <div className="page-index__joins">
        <div className="page-index__joins__show">
          <div className="page-index__joins__show__line1">
            {items.map((s, i) => (
              <div className={`joins-line joins-line--${i}`} key={`join-line1-${i}`}>{s[0]}</div>
            ))}
            <div className="point-line point--left" />
          </div>
          <div className="page-index__joins__show__line2">
            {items.map((s, i) => (
              <div className={`joins-line joins-line--${i}`} key={`join-line2-${i}`}>{s[1]}</div>
            ))}
            <div className="point-line point--left" />
          </div>
        </div>
      </div>
    </div>
  );
};

Block.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locale: PropTypes.string,
  }),
  itemsStr: PropTypes.string,
}

export default injectIntl(Block)
