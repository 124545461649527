import React from "react";
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby";
import { injectIntl, changeLocale } from "gatsby-plugin-intl";

import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import Header from '../components/layout/header';
import Footer from '../components/layout/footer';
import Counters from "../components/counters";
import Joins from '../pageBlocks/indexJoins';
import Hero from '../pageBlocks/indexHero';
import Doing from '../pageBlocks/indexDoing';
import Projects from '../pageBlocks/indexProjects';

import './index.scss';
import 'animate.css/animate.min.css';

const QUERY = graphql`
  query IndexTop {
    allStrapiContent(filter: { key: {eq: "index_top"}}) {
      edges {
        node {
          body
          header
          content
          key
          lang
          Seo {
            title
            description
            keywords
          }
          file {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blocks {
            header
            details
          }
        }
      }
    }
  }
`

const Index = ({ intl }) => {
  const result = useStaticQuery(QUERY);
  const { node } = result.allStrapiContent.edges.find(n => n.node.lang === intl.locale);

  React.useEffect(() => {
  }, []);

  const scrollToContacts = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }

  return (
    <Layout showMenu={false} fullPage>
      <SEO title={node.header} {...node.Seo} />
      <Header header={node.Seo.header} pageHeader={node.header} />
      <div className="page-index">
        <Joins itemsStr={node.body} />
        <Hero content={node.content} image={node.file} />
        <Doing nodes={node.blocks} />
        <div className="page-index__request">
          <div className="page-index__request__image" />
          <button
            className="btn btn-primary"
            onClick={scrollToContacts}
          >
            <span className="point-line point--left"></span>
            {intl.formatMessage({ id: 'home.submit_request' })}
          </button>
        </div>
        <Projects />
      </div>
      <Footer />
      <Counters />
    </Layout>
  );
}

Index.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locale: PropTypes.string,
  }),
}

export default injectIntl(Index)
