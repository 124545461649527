import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from "gatsby-plugin-intl";
import ReactMarkdown from 'react-markdown';

import './indexDoing.scss';

const Block = ({ nodes }) => {
  const [servicesNode, advantagesNode] = nodes;
  const servicesList = servicesNode.details.split('\n');
  return (
    <div className="content">
      <div className="page-index__doing">
        <h2 className="big-title center wow slideInUp">{servicesNode.header}</h2>
        <div className="page-index__doing__services">
          <ul>
            {servicesList.map((s,i) => (
              <li key={`service-${i}`} className="wow fadeIn" data-wow-delay={`${i/4}s`}>
                <div className="point-line" />
                {s}
              </li>
            ))}
          </ul>
        </div>
        <div className="page-index__doing__advantages wow slideInUp">
          <ReactMarkdown source={advantagesNode.details} />
        </div>
      </div>
    </div>
  );
};

Block.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locale: PropTypes.string,
  }),
  nodes: PropTypes.array,
}

export default injectIntl(Block)
