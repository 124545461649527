import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Img from "gatsby-image";

import Project from '../components/projectUnit';

import './indexProjects.scss';

const QUERY = graphql`
query Projects {
  allStrapiProject(filter: { selected: { eq: true }, active: { eq: true } }, sort: { order: DESC, fields: pub_date }) {
    edges {
      node {
        id
        name
        lang
        slug
        list_description
        selected
        list_image {
          childImageSharp {
            fluid(maxHeight: 600, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`

const Block = ({ intl }) => {
  const result = useStaticQuery(QUERY);
  const projects = result.allStrapiProject.edges.filter(n => n.node.lang === intl.locale).map((p) => p.node);
  React.useEffect(() => {
  });
  return (
    <div className="page-home__projects">
      <div className="content">
        <h2 className="big-title center">
          <FormattedMessage id="home.selected_projects_title" />
        </h2>
        <div className="projects-list">
          {projects.map((project, i) => <Project key={project.slug} item={project} />)}
        </div>
        <div className="projects__more-link">
          <Link to="/projects">
            <FormattedMessage id="home.projects_more_link" />
            <span className="point-line point-line--white" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(Block)
